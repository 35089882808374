import * as React from 'react'
import {
    Outlet,
} from "react-router-dom"
import {
    CssBaseline,
    Container,
} from "@mui/material"
import Header from './header'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const customTheme = createTheme({
    palette: {
        primary: {
            main: "#fffff"
        }
    }
});

export default function Layout() {
    return (
        <ThemeProvider theme={customTheme}>
            <React.Fragment>
                <CssBaseline />
                <Header />
                <Container sx={{ mt: 20 }}>
                    <Outlet />
                </Container>
            </React.Fragment>
        </ThemeProvider>
    )
}
