import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName } from './base';

export const thunkSubmissionItemApprovalApi = 'api/approval-pengajuan-alat-barang';
export const sliceSubmissionItemApprovalName = 'approval-pengajuan-alat-barang';
export const thunkExportSubmissionItemApproval = 'api/approval-pengajuan-alat-barang/export-xlsx';

export const exportSubmissionItemApproval = createAsyncThunk(createThunkName(thunkExportSubmissionItemApproval), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkExportSubmissionItemApproval, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSubmissionItemApproval = createAsyncThunk(getThunkName(thunkSubmissionItemApprovalApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkSubmissionItemApprovalApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const approvalSubmissionItemApproval = createAsyncThunk(createThunkName(thunkSubmissionItemApprovalApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + thunkSubmissionItemApprovalApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const SubmissionItemApprovalSlice = createSlice({
  name: sliceSubmissionItemApprovalName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    approval: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    export: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: state => {
      state.export = {
      data: {},
      status: 'idle',
      message: '',
      error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubmissionItemApproval.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getSubmissionItemApproval.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getSubmissionItemApproval.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(approvalSubmissionItemApproval.pending, state => {
        state.approval.data = {};
        state.approval.status = 'loading';
        state.approval.message = '';
        state.approval.error = '';
      })
      .addCase(approvalSubmissionItemApproval.fulfilled, (state, action) => {
        state.approval.data = action.payload.data;
        state.approval.status = 'succeeded';
        state.approval.message = action.payload.message;
        state.approval.error = '';
      })
      .addCase(approvalSubmissionItemApproval.rejected, (state, action) => {
        state.approval.data = {};
        state.approval.status = 'failed';
        state.approval.message = action.payload.message;
        state.approval.error = action.payload.error;
      })
      .addCase(exportSubmissionItemApproval.pending, state => {
        state.export.data = {};
        state.export.status = 'loading';
        state.export.message = '';
        state.export.error = '';
      })
      .addCase(exportSubmissionItemApproval.fulfilled, (state, action) => {
        state.export.data = action.payload.data;
        state.export.status = 'succeeded';
        state.export.message = action.payload.message;
        state.export.error = '';
      })
      .addCase(exportSubmissionItemApproval.rejected, (state, action) => {
        state.export.data = {};
        state.export.status = 'failed';
        state.export.message = action.payload.message;
        state.export.error = action.payload.error;
      });
  },
});

export const { reset } = SubmissionItemApprovalSlice.actions;
export default SubmissionItemApprovalSlice.reducer;
