// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

const checkStatus = async (response) => {
  if (!response) {
    const errorObj = {
      status: 500,
      data: null,
      message: "Server doesn't give any responses",
      error: "Server doesn't give any responses",
      show_message: true,
    };
    throw errorObj;
  }
  if (response.status === 401) {
    window.location.replace("/admin/login");
    const errorObj = {
      status: response.status,
      url: response.url,
      error: "Unauthorized",
      message: "Unauthorized",
      show_message: true,
    };
    throw errorObj;
  } else if (response.status === 403) {
    // window.location.replace("/error/403");
    const errorObj = {
      status: response.status,
      url: response.url,
      error: "Forbidden",
      message: "Forbidden",
      show_message: true,
    };
    throw errorObj;
  }
  let data = await response.json();
  if (response.ok || (response.status >= 200 && response.status < 300)) {
    // Return a result object similar to Axios
    return {
      status: response.status,
      data,
      headers: response.headers,
      url: response.url,
    };
  }
  let error = data.errors && data.errors.join("<br />");
  const errorObj = {
    status: response.status,
    url: response.url,
    error: error,
    message: data,
    data: data,
    show_message: true,
  };

  throw errorObj;
};

export async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = { "Content-Type": "application/json" };

  const config = {
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    body,
  };

  let data;
  try {
    const response = await fetch(endpoint, config).then(checkStatus);
    return response;
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}

export async function clientFormData(endpoint, { body, ...customConfig } = {}) {
  const config = {
    ...customConfig,
    headers: {
      ...customConfig.headers,
    },
    body,
  };

  try {
    const response = await fetch(endpoint, config).then(checkStatus);
    return response;
  } catch (err) {
    return Promise.reject(err.message ? err.message : err);
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "GET" });
};

client.post = function (endpoint, body, customConfig = {}) {
  body = JSON.stringify(body);
  return client(endpoint, { body, ...customConfig, method: "POST" });
};

client.put = function (endpoint, body, customConfig = {}) {
  body = JSON.stringify(body);
  return client(endpoint, { body, ...customConfig, method: "PUT" });
};

clientFormData.post = function (endpoint, body, customConfig = {}) {
  return clientFormData(endpoint, { body, ...customConfig, method: "POST" });
};

client.delete = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "DELETE" });
};

export const BASE_URL = process.env.REACT_APP_API_URL;
