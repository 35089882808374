import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName } from './base';

export const thunkSubmissionRepairApprovalApi = 'api/approval-pengajuan-perbaikan';
export const sliceSubmissionRepairApprovalName = 'approval-pengajuan-perbaikan';
export const thunkExportSubmissionRepairApprovalApi = 'api/approval-pengajuan-perbaikan/export-xlsx';

export const exportSubmissionRepairApproval = createAsyncThunk(createThunkName(thunkExportSubmissionRepairApprovalApi), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkExportSubmissionRepairApprovalApi, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSubmissionRepairApproval = createAsyncThunk(getThunkName(thunkSubmissionRepairApprovalApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkSubmissionRepairApprovalApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const approvalSubmissionRepairApproval = createAsyncThunk(createThunkName(thunkSubmissionRepairApprovalApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + thunkSubmissionRepairApprovalApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const SubmissionRepairApprovalSlice = createSlice({
  name: sliceSubmissionRepairApprovalName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    approval: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    export: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: state => {
      state.export = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubmissionRepairApproval.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getSubmissionRepairApproval.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getSubmissionRepairApproval.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(approvalSubmissionRepairApproval.pending, state => {
        state.approval.data = {};
        state.approval.status = 'loading';
        state.approval.message = '';
        state.approval.error = '';
      })
      .addCase(approvalSubmissionRepairApproval.fulfilled, (state, action) => {
        state.approval.data = action.payload.data;
        state.approval.status = 'succeeded';
        state.approval.message = action.payload.message;
        state.approval.error = '';
      })
      .addCase(approvalSubmissionRepairApproval.rejected, (state, action) => {
        state.approval.data = {};
        state.approval.status = 'failed';
        state.approval.message = action.payload.message;
        state.approval.error = action.payload.error;
      })
      .addCase(exportSubmissionRepairApproval.pending, state => {
        state.export.data = {};
        state.export.status = 'loading';
        state.export.message = '';
        state.export.error = '';
      })
      .addCase(exportSubmissionRepairApproval.fulfilled, (state, action) => {
        state.export.data = action.payload.data;
        state.export.status = 'succeeded';
        state.export.message = action.payload.message;
        state.export.error = '';
      })
      .addCase(exportSubmissionRepairApproval.rejected, (state, action) => {
        state.export.data = {};
        state.export.status = 'failed';
        state.export.message = action.payload.message;
        state.export.error = action.payload.error;
      });
  },
});

export const { reset } = SubmissionRepairApprovalSlice.actions;
export default SubmissionRepairApprovalSlice.reducer;
