import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, showThunkName } from './base';

export const thunkPaidLeaveEmployeeApi = 'api/pengajuan-cuti-pegawai';
export const slicePaidLeaveEmployeeName = 'pengajuan-cuti-pegawai';

export const getPaidLeaveEmployee = createAsyncThunk(getThunkName(thunkPaidLeaveEmployeeApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkPaidLeaveEmployeeApi + '/me?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createPaidLeaveEmployee = createAsyncThunk(createThunkName(thunkPaidLeaveEmployeeApi), async (params, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkPaidLeaveEmployeeApi, params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getResponsibleEmployee = createAsyncThunk(showThunkName(thunkPaidLeaveEmployeeApi), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkPaidLeaveEmployeeApi + '/pegawai-list', {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const PaidLeaveEmployeeSlice = createSlice({
  name: slicePaidLeaveEmployeeName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    getResponsible: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPaidLeaveEmployee.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getPaidLeaveEmployee.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getPaidLeaveEmployee.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload;
        state.get.error = action.error.message;
        state.get.pagination = {};
      })
      .addCase(createPaidLeaveEmployee.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createPaidLeaveEmployee.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createPaidLeaveEmployee.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(getResponsibleEmployee.pending, state => {
        state.getResponsible.data = [];
        state.getResponsible.status = 'loading';
        state.getResponsible.message = '';
        state.getResponsible.error = '';
      })
      .addCase(getResponsibleEmployee.fulfilled, (state, action) => {
        state.getResponsible.data = action.payload.data;
        state.getResponsible.status = 'succeeded';
        state.getResponsible.message = action.payload.message;
        state.getResponsible.error = '';
      })
      .addCase(getResponsibleEmployee.rejected, (state, action) => {
        state.getResponsible.data = [];
        state.getResponsible.status = 'failed';
        state.getResponsible.message = action.payload;
        state.getResponsible.error = action.error.message;
      });
  },
});

export default PaidLeaveEmployeeSlice.reducer;
