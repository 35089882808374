import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, clientFormData, BASE_URL } from '../api/client';
import { getThunkName, createOrUpdateThunkName, deleteThunkName } from './base';
import { thunkEmployeeApi } from './employee';

export const thunkEmployeeEducationCourse = 'pendidikan/kursus';
export const sliceEmployeeEducationCourseName = 'pendidikan-kursus-pegawai';

export const getEmployeeEducationCourse = createAsyncThunk(getThunkName(thunkEmployeeEducationCourse), async (idEmployee, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeEducationCourse, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createOrUpdateEmployeeEducationCourse = createAsyncThunk(
  createOrUpdateThunkName(thunkEmployeeEducationCourse),
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientFormData.post(BASE_URL + thunkEmployeeApi + '/' + body.get('id_peg_biodata') + '/' + thunkEmployeeEducationCourse, body, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteEmployeeEducationCourse = createAsyncThunk(
  deleteThunkName(thunkEmployeeEducationCourse),
  async ({ idEmployee, id }, { rejectWithValue }) => {
    try {
      const response = await client.delete(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeEducationCourse + '/' + id, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const EmployeeEducationCourseSlice = createSlice({
  name: sliceEmployeeEducationCourseName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
    },
    createOrUpdate: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEmployeeEducationCourse.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
      })
      .addCase(getEmployeeEducationCourse.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
      })
      .addCase(getEmployeeEducationCourse.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
      })
      .addCase(createOrUpdateEmployeeEducationCourse.pending, state => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = 'loading';
        state.createOrUpdate.message = '';
        state.createOrUpdate.error = '';
      })
      .addCase(createOrUpdateEmployeeEducationCourse.fulfilled, (state, action) => {
        state.createOrUpdate.data = action.payload.data;
        state.createOrUpdate.status = 'succeeded';
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = '';
      })
      .addCase(createOrUpdateEmployeeEducationCourse.rejected, (state, action) => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = 'failed';
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = action.payload.error;
      })
      .addCase(deleteEmployeeEducationCourse.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteEmployeeEducationCourse.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteEmployeeEducationCourse.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default EmployeeEducationCourseSlice.reducer;
