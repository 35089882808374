import { createSlice } from "@reduxjs/toolkit";

export const sliceGeneralName = "general";
export const snackbarSeverityInfo = "info";
export const snackbarSeveritySuccess = "success";
export const snackbarSeverityWarning = "warning";
export const snackbarSeverityDanger = "danger";

export const generalSlice = createSlice({
  name: sliceGeneralName,
  initialState: {
    snackbar: {
      message: "",
      show_message: false,
      severity: snackbarSeverityInfo,
    },
    showloading: false,
    myMenu: [],
  },
  reducers: {
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setShowloading: (state, action) => {
      state.showloading = action.payload;
    },
  },
});

export const { setSnackbar, setShowloading, setMyMenu } = generalSlice.actions;
export default generalSlice.reducer;
