import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';

export const thunkKabupatenName = 'api/master/kokab';
export const sliceKabupatenName = 'kabupaten';

export const getData = createAsyncThunk(thunkKabupatenName, async (idProvinsi, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkKabupatenName + '/' + idProvinsi);
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const kabupatenSlice = createSlice({
  name: sliceKabupatenName,
  initialState: {
    data: [],
    status: 'idle',
    message: '',
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getData.pending, (state, action) => {
        state.data = [];
        state.status = 'loading';
        state.message = '';
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.status = 'succeeded';
        state.message = action.payload.message;
        state.error = null;
      })
      .addCase(getData.rejected, (state, action) => {
        state.data = [];
        state.status = 'failed';
        state.message = action.payload.message;
        state.error = action.payload.errors;
      });
  },
});

export default kabupatenSlice.reducer;
