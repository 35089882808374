import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, updateThunkName } from './base';

export const thunkPresenceApi = 'api/kelola-presensi-pegawai';
export const slicePresenceName = 'kelola-presensi-pegawai';

export const getPresence = createAsyncThunk(getThunkName(thunkPresenceApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkPresenceApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updatePresenceSettings = createAsyncThunk(updateThunkName(`${thunkPresenceApi}/settings`), async (params, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + `${thunkPresenceApi}/settings`, params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const PresenceSlice = createSlice({
  name: slicePresenceName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    updateSettings: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPresence.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getPresence.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getPresence.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload;
        state.get.error = action.error.message;
        state.get.pagination = {};
      })
      .addCase(updatePresenceSettings.pending, state => {
        state.updateSettings.data = {};
        state.updateSettings.status = 'loading';
        state.updateSettings.message = '';
        state.updateSettings.error = '';
      })
      .addCase(updatePresenceSettings.fulfilled, (state, action) => {
        state.updateSettings.data = action.payload.data;
        state.updateSettings.status = 'succeeded';
        state.updateSettings.message = action.payload.message;
        state.updateSettings.error = '';
      })
      .addCase(updatePresenceSettings.rejected, (state, action) => {
        state.updateSettings.data = {};
        state.updateSettings.status = 'failed';
        state.updateSettings.message = action.payload;
        state.updateSettings.error = action.error.message;
      })
  },
});

export default PresenceSlice.reducer;
