import React from 'react';
import { useEffect, Suspense } from 'react';
import IndexRoutes from './routes';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  const { enqueueSnackbar } = useSnackbar();

  const snackbar = useSelector(state => state.general.snackbar);
  const showloading = useSelector(state => state.general.showloading);

  useEffect(() => {
    if (showloading) {
    }

    if (snackbar.message && snackbar.message.length > 0 && snackbar.show_message) {
      enqueueSnackbar(snackbar.message, {
        variant: snackbar.severity,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
    }
  }, [snackbar, showloading]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <HelmetProvider>
        <BrowserRouter>
          <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1000 }} open={showloading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <IndexRoutes />
        </BrowserRouter>
      </HelmetProvider>
    </Suspense>
  );
};

App.propTypes = {
  history: PropTypes.object,
};

export default App;
