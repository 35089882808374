import { configureStore } from '@reduxjs/toolkit';
import authReducer, * as auth from './redux/auth';
import myMenuReducer from './redux/my-menu';
import profileReducer from './redux/profile';
import roleReducer from './redux/role';
import rolePermissionReducer from './redux/roles-permissions';
import userRoleReducer from './redux/user-roles';
import employeeReducer from './redux/employee';
import employeeFamilyReducer from './redux/employee-family';
import employeeAddressReducer from './redux/employee-address';
import employeeEducationReducer from './redux/employee-education';
import employeeEducationCourseReducer from './redux/employee-education-course';
import employeeEducationFunctionalReducer from './redux/employee-education-functional-training';
import employeeEducationStructuralReducer from './redux/employee-education-structural-training';
import employeeEducationSupportReducer from './redux/employee-education-support-training';
import employeeEducationVolunteerReducer from './redux/employee-education-volunteer';
import employeeHistoryPositionReducer from './redux/employee-history-position';
import employeeHistoryRankReducer from './redux/employee-history-rank';
import employeeOtherDataReducer from './redux/employee-other-data';
import masterDataReducer from './redux/master-data';
import presenceReducer from './redux/presence';
import presenceEmployeeReducer from './redux/presence-employee';
import logBookReducer from './redux/log-book';
import logBookEmployeeReducer from './redux/log-book-employee';
import paidLeaveReducer from './redux/paid-leave';
import paidLeaveEmployeeReducer from './redux/paid-leave-employee';
import timeOffReducer from './redux/time-off';
import settingsReducer from './redux/settings';
import incomingMailReducer from './redux/incoming-mail';
import incomingMailApprovalReducer from './redux/incoming-mail-approval';
import outcomingMailReducer from './redux/outcoming-mail';
import outcomingMailApprovalReducer from './redux/outcoming-mail-approval';
import facilityLoanReducer from './redux/facility-loan';
import facilityLoanApprovalReducer from './redux/facility-loan-approval';
import submissionItemReducer from './redux/submission-item';
import submissionItemApprovalReducer from './redux/submission-item-approval';
import submissionRepairReducer from './redux/submission-repair';
import submissionRepairApprovalReducer from './redux/submission-repair-approval';
import studentReducer from './redux/student';
import notificationReducer from './redux/notification';
import procurementReducer from './redux/procurement';
import procurementApprovalReducer from './redux/procurement-approval';
import dashboardReducer from './redux/dashboard';
import storageReducer from './redux/storage';
import provinsiReducer from './redux/provinsi';
import kabupatenReducer from './redux/kabupaten';
import kecamatanReducer from './redux/kecamatan';
import kelurahanReducer from './redux/kelurahan';
import generalReducer, * as general from './redux/general';

const authMiddleware = (store) => (next) => (action) => {
  if (action.type === auth.postLogin.fulfilled.type) {
    // Note: localStorage expects a string
    localStorage.setItem("account", JSON.stringify(action.payload.data));
    localStorage.setItem(
      "token",
      "Bearer " + action.payload.data.roles[0].token
    );
    localStorage.setItem("current_role", action.payload.data.roles[0].nama);
    localStorage.setItem("roles", JSON.stringify(action.payload.data.roles));
    window.location.replace("/admin");
  } else if (action.type === auth.doLogout.fulfilled.type) {
    localStorage.removeItem("account");
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    window.location.replace("/admin/login");
  }
  return next(action);
};

const loadingMiddleware = (store) => (next) => (action) => {
  if (action.type.includes("pending")) {
    store.dispatch({
      type: general.generalSlice.actions.setShowloading.type,
      payload: true,
    });
  } else if (action.type.includes("fulfilled")) {
    store.dispatch({
      type: general.generalSlice.actions.setShowloading.type,
      payload: false,
    });
  } else if (action.type.includes("rejected")) {
    store.dispatch({
      type: general.generalSlice.actions.setShowloading.type,
      payload: false,
    });
  }

  return next(action);
};

const snackbarMiddleware = (store) => (next) => (action) => {
  if (action.type === general.generalSlice.actions.setSnackbar.type) {
    return next(action);
  }
  if (action.payload) {
    let showMessage = action.payload.show_message;
    let message = "";
    let severity = general.snackbarSeveritySuccess;
    if (action.type.includes("fulfilled")) {
      message = action.payload.message ? action.payload.message : "";
      severity = general.snackbarSeveritySuccess;
    } else if (action.type.includes("rejected")) {
      showMessage = true;
      if (action.payload.message) message = action.payload.message
      else if (action.payload) message = action.payload
      else if (action.message) message = action.message
      severity = general.snackbarSeverityWarning;
    }
    store.dispatch({
      type: general.generalSlice.actions.setSnackbar.type,
      payload: {
        message: message,
        severity: severity,
        show_message: showMessage,
      },
    });
  }
  return next(action);
};

export default configureStore({
  reducer: {
    general: generalReducer,
    auth: authReducer,
    myMenu: myMenuReducer,
    profile: profileReducer,
    role: roleReducer,
    rolePermission: rolePermissionReducer,
    userRole: userRoleReducer,
    employee: employeeReducer,
    employeeFamily: employeeFamilyReducer,
    employeeAddress: employeeAddressReducer,
    employeeEducation: employeeEducationReducer,
    employeeEducationCourse: employeeEducationCourseReducer,
    employeeEducationFunctional: employeeEducationFunctionalReducer,
    employeeEducationStructural: employeeEducationStructuralReducer,
    employeeEducationSupport: employeeEducationSupportReducer,
    employeeEducationVolunteer: employeeEducationVolunteerReducer,
    employeeHistoryPosition: employeeHistoryPositionReducer,
    employeeHistoryRank: employeeHistoryRankReducer,
    employeeOtherData: employeeOtherDataReducer,
    masterData: masterDataReducer,
    presence: presenceReducer,
    presenceEmployee: presenceEmployeeReducer,
    logBook: logBookReducer,
    logBookEmployee: logBookEmployeeReducer,
    paidLeave: paidLeaveReducer,
    paidLeaveEmployee: paidLeaveEmployeeReducer,
    timeOff: timeOffReducer,
    settings: settingsReducer,
    incomingMail: incomingMailReducer,
    incomingMailApproval: incomingMailApprovalReducer,
    outcomingMail: outcomingMailReducer,
    outcomingMailApproval: outcomingMailApprovalReducer,
    facilityLoan: facilityLoanReducer,
    facilityLoanApproval: facilityLoanApprovalReducer,
    submissionItem: submissionItemReducer,
    submissionItemApproval: submissionItemApprovalReducer,
    submissionRepair: submissionRepairReducer,
    submissionRepairApproval: submissionRepairApprovalReducer,
    student: studentReducer,
    notification: notificationReducer,
    procurement: procurementReducer,
    procurementApproval: procurementApprovalReducer,
    dashboard: dashboardReducer,
    storage: storageReducer,
    provinsi: provinsiReducer,
    kabupaten: kabupatenReducer,
    kecamatan: kecamatanReducer,
    kelurahan: kelurahanReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loadingMiddleware)
      .concat(authMiddleware)
      .concat(snackbarMiddleware),
});
