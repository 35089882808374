import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client, BASE_URL } from "../api/client";
import { getThunkName, createOrUpdateThunkName, showThunkName } from "./base";

export const thunkPresenceEmployeeApi = "api/presensi-pegawai";
export const slicePresenceEmployeeName = "presensi-pegawai";

export const getPresenceEmployee = createAsyncThunk(
  getThunkName(thunkPresenceEmployeeApi),
  async (params = "", { rejectWithValue }) => {
    try {
      const response = await client.get(
        BASE_URL + thunkPresenceEmployeeApi + "/me?" + params,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createOrUpdatePresenceEmployee = createAsyncThunk(
  createOrUpdateThunkName(thunkPresenceEmployeeApi),
  async (type, { rejectWithValue }) => {
    try {
      const response = await client.post(
        BASE_URL + thunkPresenceEmployeeApi + "/" + type,
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTodayPresenceEmployee = createAsyncThunk(
  showThunkName(thunkPresenceEmployeeApi),
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get(
        BASE_URL + thunkPresenceEmployeeApi + "/today",
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const PresenceEmployeeSlice = createSlice({
  name: slicePresenceEmployeeName,
  initialState: {
    get: {
      data: [],
      status: "idle",
      message: "",
      error: "",
      pagination: {},
    },
    createOrUpdate: {
      data: {},
      status: "idle",
      message: "",
      error: "",
    },
    getToday: {
      data: {},
      status: "idle",
      message: "",
      error: "",
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPresenceEmployee.pending, (state) => {
        state.get.data = [];
        state.get.status = "loading";
        state.get.message = "";
        state.get.error = "";
        state.get.pagination = {};
      })
      .addCase(getPresenceEmployee.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = "succeeded";
        state.get.message = action.payload.message;
        state.get.error = "";
        state.get.pagination = action.payload.meta;
      })
      .addCase(getPresenceEmployee.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = "failed";
        state.get.message = action.payload;
        state.get.error = action.error.message;
        state.get.pagination = {};
      })
      .addCase(createOrUpdatePresenceEmployee.pending, (state) => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = "loading";
        state.createOrUpdate.message = "";
        state.createOrUpdate.error = "";
      })
      .addCase(createOrUpdatePresenceEmployee.fulfilled, (state, action) => {
        state.createOrUpdate.data = action.payload.data;
        state.createOrUpdate.status = "succeeded";
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = "";
      })
      .addCase(createOrUpdatePresenceEmployee.rejected, (state, action) => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = "failed";
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = action.payload.error;
      })
      .addCase(getTodayPresenceEmployee.pending, (state) => {
        state.getToday.data = {};
        state.getToday.status = "loading";
        state.getToday.message = "";
        state.getToday.error = "";
      })
      .addCase(getTodayPresenceEmployee.fulfilled, (state, action) => {
        state.getToday.data = action.payload.data;
        state.getToday.status = "succeeded";
        state.getToday.message = action.payload.message;
        state.getToday.error = "";
      })
      .addCase(getTodayPresenceEmployee.rejected, (state, action) => {
        state.getToday.data = {};
        state.getToday.status = "failed";
        state.getToday.message = action.payload;
        state.getToday.error = action.error.message;
      });
  },
});

export default PresenceEmployeeSlice.reducer;
