import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName } from './base';

export const thunkIncomingMailApprovalApi = 'api/approval-surat-masuk';
export const sliceIncomingMailApprovalName = 'approval-surat-masuk';
export const thunkExportIncomingMailApproval = 'api/approval-surat-masuk/export-xlsx';

export const exportIncomingMailApproval = createAsyncThunk(createThunkName(thunkExportIncomingMailApproval), async (body, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkExportIncomingMailApproval, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getIncomingMailApproval = createAsyncThunk(getThunkName(thunkIncomingMailApprovalApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkIncomingMailApprovalApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const approvalIncomingMailApproval = createAsyncThunk(createThunkName(thunkIncomingMailApprovalApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + thunkIncomingMailApprovalApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const IncomingMailApprovalSlice = createSlice({
  name: sliceIncomingMailApprovalName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    approval: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    export: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: state => {
      state.export = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getIncomingMailApproval.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getIncomingMailApproval.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getIncomingMailApproval.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(exportIncomingMailApproval.pending, state => {
        state.export.data = {};
        state.export.status = 'loading';
        state.export.message = '';
        state.export.error = '';
      })
      .addCase(exportIncomingMailApproval.fulfilled, (state, action) => {
        state.export.data = action.payload.data;
        state.export.status = 'succeeded';
        state.export.message = action.payload.message;
        state.export.error = '';
      })
      .addCase(exportIncomingMailApproval.rejected, (state, action) => {
        state.export.data = {};
        state.export.status = 'failed';
        state.export.message = action.payload.message;
        state.export.error = action.payload.error;
      })
      .addCase(approvalIncomingMailApproval.pending, state => {
        state.approval.data = {};
        state.approval.status = 'loading';
        state.approval.message = '';
        state.approval.error = '';
      })
      .addCase(approvalIncomingMailApproval.fulfilled, (state, action) => {
        state.approval.data = action.payload.data;
        state.approval.status = 'succeeded';
        state.approval.message = action.payload.message;
        state.approval.error = '';
      })
      .addCase(approvalIncomingMailApproval.rejected, (state, action) => {
        state.approval.data = {};
        state.approval.status = 'failed';
        state.approval.message = action.payload.message;
        state.approval.error = action.payload.error;
      });
  },
});

export const { reset } = IncomingMailApprovalSlice.actions;
export default IncomingMailApprovalSlice.reducer;
