import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, clientFormData, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName, deleteThunkName } from './base';

export const thunkSubmissionRepairApi = 'api/pengajuan-perbaikan';
export const sliceSubmissionRepairName = 'pengajuan-perbaikan';

export const getSubmissionRepair = createAsyncThunk(getThunkName(thunkSubmissionRepairApi), async ({ params = '' }, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkSubmissionRepairApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createSubmissionRepair = createAsyncThunk(createThunkName(thunkSubmissionRepairApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkSubmissionRepairApi + '/create', body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateSubmissionRepair = createAsyncThunk(updateThunkName(thunkSubmissionRepairApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkSubmissionRepairApi + '/update', body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteSubmissionRepair = createAsyncThunk(deleteThunkName(thunkSubmissionRepairApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkSubmissionRepairApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const SubmissionRepairSlice = createSlice({
  name: sliceSubmissionRepairName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSubmissionRepair.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getSubmissionRepair.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getSubmissionRepair.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(createSubmissionRepair.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createSubmissionRepair.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createSubmissionRepair.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateSubmissionRepair.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateSubmissionRepair.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateSubmissionRepair.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(deleteSubmissionRepair.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteSubmissionRepair.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteSubmissionRepair.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default SubmissionRepairSlice.reducer;
