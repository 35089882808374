import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName } from './base';

export const thunkOutcomingMailApprovalApi = 'api/kelola-surat-keluar';
export const sliceOutcomingMailApprovalName = 'kelola-surat-keluar';
export const thunkExportOutcomingMailApprovalApi = 'api/kelola-surat-keluar/export-xlsx';

export const exportOutcomingMailApproval = createAsyncThunk(getThunkName(thunkExportOutcomingMailApprovalApi), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkExportOutcomingMailApprovalApi, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getOutcomingMailApproval = createAsyncThunk(getThunkName(thunkOutcomingMailApprovalApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkOutcomingMailApprovalApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const approvalOutcomingMailApproval = createAsyncThunk(createThunkName(thunkOutcomingMailApprovalApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + thunkOutcomingMailApprovalApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const OutcomingMailApprovalSlice = createSlice({
  name: sliceOutcomingMailApprovalName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    approval: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    export: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: state => {
      state.export = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getOutcomingMailApproval.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getOutcomingMailApproval.fulfilled, (state, action) => {
        state.get.data = action.payload.data || [];
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getOutcomingMailApproval.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(approvalOutcomingMailApproval.pending, state => {
        state.approval.data = {};
        state.approval.status = 'loading';
        state.approval.message = '';
        state.approval.error = '';
      })
      .addCase(approvalOutcomingMailApproval.fulfilled, (state, action) => {
        state.approval.data = action.payload.data;
        state.approval.status = 'succeeded';
        state.approval.message = action.payload.message;
        state.approval.error = '';
      })
      .addCase(approvalOutcomingMailApproval.rejected, (state, action) => {
        state.approval.data = {};
        state.approval.status = 'failed';
        state.approval.message = action.payload.message;
        state.approval.error = action.payload.error;
      })
      .addCase(exportOutcomingMailApproval.pending, state => {
        state.export.data = {};
        state.export.status = 'loading';
        state.export.message = '';
        state.export.error = '';
      })
      .addCase(exportOutcomingMailApproval.fulfilled, (state, action) => {
        state.export.data = action.payload.data;
        state.export.status = 'succeeded';
        state.export.message = action.payload.message;
        state.export.error = '';
      })
      .addCase(exportOutcomingMailApproval.rejected, (state, action) => {
        state.export.data = {};
        state.export.status = 'failed';
        state.export.message = action.payload.message;
        state.export.error = action.payload.error;
      });
  },
});

export const { reset } = OutcomingMailApprovalSlice.actions;
export default OutcomingMailApprovalSlice.reducer;
