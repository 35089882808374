import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, clientFormData, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName, deleteThunkName } from './base';

export const thunkOutcomingMailApi = 'api/ajuan-surat-keluar';
export const sliceOutcomingMailName = 'ajuan-surat-keluar';

export const getOutcomingMail = createAsyncThunk(getThunkName(thunkOutcomingMailApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkOutcomingMailApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createOutcomingMail = createAsyncThunk(createThunkName(thunkOutcomingMailApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkOutcomingMailApi + '/create', body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateOutcomingMail = createAsyncThunk(updateThunkName(thunkOutcomingMailApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkOutcomingMailApi + '/update', body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteOutcomingMail = createAsyncThunk(deleteThunkName(thunkOutcomingMailApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkOutcomingMailApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const OutcomingMailSlice = createSlice({
  name: sliceOutcomingMailName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOutcomingMail.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getOutcomingMail.fulfilled, (state, action) => {
        state.get.data = action.payload.data || [];
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getOutcomingMail.rejected, (state, action) => {
        state.get.data = [
          {
            id: 1,
            penerima_surat: 'penerima_surat',
            alamat_tujuan: 'alamat_tujuan',
            tembusan: 'tembusan',
            perihal: 'perihal',
            lampiran: 'lampiran',
            sifat_surat: 'sifat_surat',
            isi_surat: 'isi_surat isi_surat isi_surat isi_surat isi_surat isi_surat isi_surat',
            status: 'status',
          }
        ];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(createOutcomingMail.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createOutcomingMail.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createOutcomingMail.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateOutcomingMail.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateOutcomingMail.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateOutcomingMail.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(deleteOutcomingMail.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteOutcomingMail.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteOutcomingMail.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default OutcomingMailSlice.reducer;
