import * as React from 'react'
import {
    AppBar,
    Container,
    Toolbar,
    Button,
    Box,
} from "@mui/material"
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { Link } from 'react-router-dom'

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export default function Header(props) {

    const handleCloseNavMenu = () => {

    }

    return (
        <ElevationScroll {...props}>
            <AppBar>
                <Container maxWidth="xl">
                    <Toolbar>
                        <img
                            alt='test'
                            src="/logo_smti_sis.png"
                            style={{ maxWidth: '40px', maxHeight: '40px' }}></img>
                        <Box justifyContent={'flex-end'} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <div>
                                <Link to={'/'}>
                                    <Button
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, display: 'block', marginRight: '30px', textTransform: 'none' }}>
                                        Home
                                    </Button>
                                </Link>
                            </div>
                            <div>
                                <Link to={'/about'}>
                                    <Button
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, display: 'block', marginRight: '30px', textTransform: 'none' }}>
                                        About
                                    </Button>
                                </Link>
                            </div>
                            <div>
                                <a href='/admin' >
                                    <Button
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, display: 'block', marginRight: '30px', textTransform: 'none' }}>
                                        Admin
                                    </Button>
                                </a>
                            </div>
                        </Box>
                    </Toolbar >
                </Container>
            </AppBar >
        </ElevationScroll>
    );
}
