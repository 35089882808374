import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName, showThunkName, deleteThunkName } from './base';

export const thunkLogBookEmployeeApi = 'api/log-harian-pegawai';
export const sliceLogBookEmployeeName = 'log-harian-pegawai';

export const getLogBookEmployee = createAsyncThunk(getThunkName(thunkLogBookEmployeeApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkLogBookEmployeeApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createLogBookEmployee = createAsyncThunk(createThunkName(thunkLogBookEmployeeApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkLogBookEmployeeApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateLogBookEmployee = createAsyncThunk(updateThunkName(thunkLogBookEmployeeApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + thunkLogBookEmployeeApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const showLogBookEmployee = createAsyncThunk(showThunkName(thunkLogBookEmployeeApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkLogBookEmployeeApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteLogBookEmployee = createAsyncThunk(deleteThunkName(thunkLogBookEmployeeApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkLogBookEmployeeApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const LogBookEmployeeSlice = createSlice({
  name: sliceLogBookEmployeeName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    show: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLogBookEmployee.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getLogBookEmployee.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getLogBookEmployee.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(createLogBookEmployee.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createLogBookEmployee.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createLogBookEmployee.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateLogBookEmployee.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateLogBookEmployee.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateLogBookEmployee.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(showLogBookEmployee.pending, state => {
        state.show.data = {};
        state.show.status = 'loading';
        state.show.message = '';
        state.show.error = '';
      })
      .addCase(showLogBookEmployee.fulfilled, (state, action) => {
        state.show.data = action.payload.data;
        state.show.status = 'succeeded';
        state.show.message = action.payload.message;
        state.show.error = '';
      })
      .addCase(showLogBookEmployee.rejected, (state, action) => {
        state.show.data = {};
        state.show.status = 'failed';
        state.show.message = action.payload.message;
        state.show.error = action.payload.error;
      })
      .addCase(deleteLogBookEmployee.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteLogBookEmployee.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteLogBookEmployee.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default LogBookEmployeeSlice.reducer;
