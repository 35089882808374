import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, deleteThunkName } from './base';

export const thunkUserRoles = 'api/user-roles';
export const sliceUserRolesName = 'user-roles';

export const getUserRoles = createAsyncThunk(getThunkName(thunkUserRoles), async (idUser, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkUserRoles + '/' + idUser, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createUserRoles = createAsyncThunk(createThunkName(thunkUserRoles), async (body, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkUserRoles, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteUserRoles = createAsyncThunk(deleteThunkName(thunkUserRoles), async (idRoleUser, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkUserRoles + '/' + idRoleUser, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const userRolesSlice = createSlice({
  name: sliceUserRolesName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserRoles.pending, (state) => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
      })
      .addCase(getUserRoles.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
      })
      .addCase(getUserRoles.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
      })
      .addCase(createUserRoles.pending, (state) => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createUserRoles.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createUserRoles.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(deleteUserRoles.pending, (state) => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteUserRoles.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteUserRoles.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default userRolesSlice.reducer;
