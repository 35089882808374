import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, showThunkName, createThunkName, updateThunkName, deleteThunkName } from './base';

export const thunkMasterApi = 'api/master';
export const sliceMasterName = 'master';

export const getMasterData = async (type) => {
  const response = await client.get(BASE_URL + thunkMasterApi + '/' + type + '?limit=1000', {
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });
  return response.data;
}

export const deleteMaster = createAsyncThunk(deleteThunkName(thunkMasterApi), async ({ id, type }, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkMasterApi + '/' + type + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateMaster = createAsyncThunk(updateThunkName(thunkMasterApi), async ({ body, type }, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + thunkMasterApi + '/' + type, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createMaster = createAsyncThunk(createThunkName(thunkMasterApi), async ({ body, type }, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkMasterApi + '/' + type, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getMaster = createAsyncThunk(getThunkName(thunkMasterApi), async ({ page, type }, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkMasterApi + '/' + type + '?page=' + (page || 1), {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log('rejectWithValue', err);
    return rejectWithValue(err);
  }
});

export const showMaster = createAsyncThunk(showThunkName(thunkMasterApi), async ({ id, type }, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkMasterApi + '/' + type + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const MasterSlice = createSlice({
  name: sliceMasterName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    show: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMaster.pending, (state, action) => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getMaster.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta || {};
      })
      .addCase(getMaster.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(showMaster.pending, (state, action) => {
        state.show.data = {};
        state.show.status = 'loading';
        state.show.message = '';
        state.show.error = '';
      })
      .addCase(showMaster.fulfilled, (state, action) => {
        state.show.data = action.payload.data;
        state.show.status = 'succeeded';
        state.show.message = action.payload.message;
        state.show.error = '';
      })
      .addCase(showMaster.rejected, (state, action) => {
        state.show.data = {};
        state.show.status = 'failed';
        state.show.message = action.payload.message;
        state.show.error = action.payload.error;
      })
      .addCase(createMaster.pending, (state, action) => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createMaster.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createMaster.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateMaster.pending, (state, action) => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateMaster.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateMaster.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(deleteMaster.pending, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteMaster.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteMaster.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default MasterSlice.reducer;
