import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client, BASE_URL } from '../api/client'

export const thunkKelurahanName = 'api/master/kelurahan'
export const sliceKelurahanName = 'kelurahan'

export const getData = createAsyncThunk(thunkKelurahanName, async (idKecamatan, { rejectWithValue }) => {
    try {
        const response = await client.get(BASE_URL + thunkKelurahanName + '/' + idKecamatan)
        return response.data
    } catch (err) {
        return rejectWithValue(err)
    }

})

export const kelurahanSlice = createSlice({
    name: sliceKelurahanName,
    initialState: {
        data: [],
        status: 'idle',
        message: '',
        error: null,
    },
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(getData.pending, (state, action) => {
                state.data = []
                state.status = 'loading'
                state.message = ''
                state.error = null
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.status = 'succeeded'
                state.message = action.payload.message
                state.error = null
            })
            .addCase(getData.rejected, (state, action) => {
                state.data = []
                state.status = 'failed'
                state.message = action.payload.message
                state.error = action.payload.errors
            })
    }
})

export default kelurahanSlice.reducer