export const getThunkName = name => {
  return 'get/' + name;
};

export const showThunkName = name => {
  return 'show/' + name;
};

export const createThunkName = name => {
  return 'create/' + name;
};

export const createOrUpdateThunkName = name => {
  return 'createOrUpdate/' + name;
};

export const updateThunkName = name => {
  return 'update/' + name;
};

export const deleteThunkName = name => {
  return 'delete/' + name;
};
