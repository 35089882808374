import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName } from './base';

export const thunkPaidLeaveApi = 'api/kelola-pengajuan-cuti-pegawai';
export const slicePaidLeaveName = 'kelola-pengajuan-cuti-pegawai';
export const thunkExportPaidLeaveApi = 'api/kelola-pengajuan-cuti-pegawai/export-xlsx';

export const exportPaidLeave = createAsyncThunk(createThunkName(thunkExportPaidLeaveApi), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkExportPaidLeaveApi, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPaidLeave = createAsyncThunk(getThunkName(thunkPaidLeaveApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkPaidLeaveApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updatePaidLeave = createAsyncThunk(updateThunkName(thunkPaidLeaveApi), async (data, { rejectWithValue }) => {
  try {
    const { type, ...params } = data;
    const response = await client.put(BASE_URL + thunkPaidLeaveApi + '/' + type, params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const PaidLeaveSlice = createSlice({
  name: slicePaidLeaveName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    export: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: (state) => {
      state.export = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPaidLeave.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getPaidLeave.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getPaidLeave.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload;
        state.get.error = action.error.message;
        state.get.pagination = {};
      })
      .addCase(updatePaidLeave.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updatePaidLeave.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updatePaidLeave.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(exportPaidLeave.pending, state => {
        state.export.data = {};
        state.export.status = 'loading';
        state.export.message = '';
        state.export.error = '';
      })
      .addCase(exportPaidLeave.fulfilled, (state, action) => {
        state.export.data = action.payload.data;
        state.export.status = 'succeeded';
        state.export.message = action.payload.message;
        state.export.error = '';
      })
      .addCase(exportPaidLeave.rejected, (state, action) => {
        state.export.data = {};
        state.export.status = 'failed';
        state.export.message = action.payload.message;
        state.export.error = action.payload.error;
      })
  },
});

export const { reset } = PaidLeaveSlice.actions;
export default PaidLeaveSlice.reducer;
