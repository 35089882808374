import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, clientFormData, BASE_URL } from '../api/client';
import { getThunkName, createOrUpdateThunkName, deleteThunkName } from './base';
import { thunkEmployeeApi } from './employee';

export const thunkEmployeeHistoryPosition = 'riwayat-jabatan';
export const sliceEmployeeHistoryPositionName = 'riwayat-jabatan-pegawai';

export const getEmployeeHistoryPosition = createAsyncThunk(getThunkName(thunkEmployeeHistoryPosition), async (idEmployee, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeHistoryPosition, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createOrUpdateEmployeeHistoryPosition = createAsyncThunk(
  createOrUpdateThunkName(thunkEmployeeHistoryPosition),
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientFormData.post(BASE_URL + thunkEmployeeApi + '/' + body.get('id_peg_biodata') + '/' + thunkEmployeeHistoryPosition, body, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteEmployeeHistoryPosition = createAsyncThunk(
  deleteThunkName(thunkEmployeeHistoryPosition),
  async ({ idEmployee, id }, { rejectWithValue }) => {
    try {
      const response = await client.delete(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeHistoryPosition + '/' + id, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const EmployeeHistoryPositionSlice = createSlice({
  name: sliceEmployeeHistoryPositionName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
    },
    createOrUpdate: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEmployeeHistoryPosition.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
      })
      .addCase(getEmployeeHistoryPosition.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
      })
      .addCase(getEmployeeHistoryPosition.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
      })
      .addCase(createOrUpdateEmployeeHistoryPosition.pending, state => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = 'loading';
        state.createOrUpdate.message = '';
        state.createOrUpdate.error = '';
      })
      .addCase(createOrUpdateEmployeeHistoryPosition.fulfilled, (state, action) => {
        state.createOrUpdate.data = action.payload.data;
        state.createOrUpdate.status = 'succeeded';
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = '';
      })
      .addCase(createOrUpdateEmployeeHistoryPosition.rejected, (state, action) => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = 'failed';
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = action.payload.error;
      })
      .addCase(deleteEmployeeHistoryPosition.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteEmployeeHistoryPosition.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteEmployeeHistoryPosition.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default EmployeeHistoryPositionSlice.reducer;
