import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName } from './base';

export const thunkFacilityLoanApprovalApi = 'api/approval-peminjaman';
export const sliceFacilityLoanApprovalName = 'approval-peminjaman';
export const thunkExportFacilityLoanApprovalApi = 'api/approval-peminjaman-';

export const exportFacilityLoanApproval = createAsyncThunk(
  createThunkName(thunkExportFacilityLoanApprovalApi),
  async ({ type = 'barang', body }, { rejectWithValue }) => {
    try {
      const response = await client.get(BASE_URL + thunkExportFacilityLoanApprovalApi + type + '/export-xlsx', {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFacilityLoanApproval = createAsyncThunk(
  getThunkName(thunkFacilityLoanApprovalApi),
  async ({ type = 'barang', params = '' }, { rejectWithValue }) => {
    try {
      const response = await client.get(BASE_URL + thunkFacilityLoanApprovalApi + '-' + type + '?' + params, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const approvalFacilityLoanApproval = createAsyncThunk(
  createThunkName(thunkFacilityLoanApprovalApi),
  async ({ type = 'barang', body }, { rejectWithValue }) => {
    try {
      const response = await client.put(BASE_URL + thunkFacilityLoanApprovalApi + '-' + type, body, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const FacilityLoanApprovalSlice = createSlice({
  name: sliceFacilityLoanApprovalName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    approval: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    export: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: state => {
      state.export = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFacilityLoanApproval.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getFacilityLoanApproval.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getFacilityLoanApproval.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(exportFacilityLoanApproval.pending, state => {
        state.export.data = {};
        state.export.status = 'loading';
        state.export.message = '';
        state.export.error = '';
      })
      .addCase(exportFacilityLoanApproval.fulfilled, (state, action) => {
        state.export.data = action.payload.data;
        state.export.status = 'succeeded';
        state.export.message = action.payload.message;
        state.export.error = '';
      })
      .addCase(exportFacilityLoanApproval.rejected, (state, action) => {
        state.export.data = {};
        state.export.status = 'failed';
        state.export.message = action.payload.message;
        state.export.error = action.payload.error;
      })
      .addCase(approvalFacilityLoanApproval.pending, state => {
        state.approval.data = {};
        state.approval.status = 'loading';
        state.approval.message = '';
        state.approval.error = '';
      })
      .addCase(approvalFacilityLoanApproval.fulfilled, (state, action) => {
        state.approval.data = action.payload.data;
        state.approval.status = 'succeeded';
        state.approval.message = action.payload.message;
        state.approval.error = '';
      })
      .addCase(approvalFacilityLoanApproval.rejected, (state, action) => {
        state.approval.data = {};
        state.approval.status = 'failed';
        state.approval.message = action.payload.message;
        state.approval.error = action.payload.error;
      });
  },
});

export const { reset } = FacilityLoanApprovalSlice.actions;
export default FacilityLoanApprovalSlice.reducer;
