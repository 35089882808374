import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, updateThunkName } from './base';

export const thunkNotificationApi = 'api/notifikasi';
export const thunkNotificationTotalUnreadApi = 'api/notifikasi/total-unread';
export const sliceNotificationName = 'notifikasi';

export const getNotification = createAsyncThunk(getThunkName(thunkNotificationApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkNotificationApi + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getNotificationTotalUnread = createAsyncThunk(getThunkName(thunkNotificationTotalUnreadApi), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkNotificationTotalUnreadApi, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateNotification = createAsyncThunk(updateThunkName(thunkNotificationApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + thunkNotificationApi + '/read/' + id, null, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const NotificationSlice = createSlice({
  name: sliceNotificationName,
  initialState: {
    unread: {
      data: 0,
      status: 'idle',
      message: '',
      error: '',
    },
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(getNotificationTotalUnread.pending, state => {
        state.unread.data = 0;
        state.unread.status = 'loading';
        state.unread.message = '';
        state.unread.error = '';
      })
      .addCase(getNotificationTotalUnread.fulfilled, (state, action) => {
        state.unread.data = action.payload.data;
        state.unread.status = 'succeeded';
        state.unread.message = action.payload.message;
        state.unread.error = '';
      })
      .addCase(getNotificationTotalUnread.rejected, (state, action) => {
        state.unread.data = 0;
        state.unread.status = 'failed';
        state.unread.message = action.payload;
        state.unread.error = action.error.message;
      })
      .addCase(getNotification.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload;
        state.get.error = action.error.message;
        state.get.pagination = {};
      })
      .addCase(updateNotification.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
  },
});

export default NotificationSlice.reducer;
