import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../components/layout';
import DashboardLayout from '../components/layoutAdmin';

const Error403Page = lazy(() => import('../pages/error/403'));

const IndexPage = lazy(() => import('../pages/user/index'));
const AboutPage = lazy(() => import('../pages/user/about'));
const DashboardPage = lazy(() => import('../pages/admin/dashboard'));
const ProcurementPage = lazy(() => import('../pages/admin/procurement'));
const ProcurementApprovalPage = lazy(() => import('../pages/admin/procurement-approval'));
const LoginPage = lazy(() => import('../pages/admin/login'));
const ForgotPasswordPage = lazy(() => import('../pages/admin/forgot-password'));
const ProfilePage = lazy(() => import('../pages/admin/profile'));
const RolePage = lazy(() => import('../pages/admin/role'));
const EmployeePage = lazy(() => import('../pages/admin/employee'));
const EmployeeDetailPage = lazy(() => import('../pages/admin/employee/detail'));
const EmployeePersonalPage = lazy(() => import('../pages/admin/employee/update/personal'));
const EmployeeAddressPage = lazy(() => import('../pages/admin/employee/update/address'));
const EmployeeFamilyPage = lazy(() => import('../pages/admin/employee/update/family/index'));
const EmployeeEducationPage = lazy(() => import('../pages/admin/employee/update/education/index'));
const EmployeeHistoryPositionPage = lazy(() => import('../pages/admin/employee/update/history-position/index'));
const EmployeeHistoryRankPage = lazy(() => import('../pages/admin/employee/update/history-rank/index'));
const EmployeeOtherDataPage = lazy(() => import('../pages/admin/employee/update/other-data/index'));
const NotificationPage = lazy(() => import('../pages/admin/notification'));
const StudentPage = lazy(() => import('../pages/admin/student'));
const MasterDataPage = lazy(() => import('../pages/admin/master-data'));
const DynamicMasterDataPage = lazy(() => import('../pages/admin/master-data/dynamic'));
const PresencePage = lazy(() => import('../pages/admin/presence'));
const PaidLeavePage = lazy(() => import('../pages/admin/paid-leave'));
const FacilityLoanPage = lazy(() => import('../pages/admin/facility-loan'));
const FacilityLoanApprovalPage = lazy(() => import('../pages/admin/facility-loan-approval'));
const IncomingMailPage = lazy(() => import('../pages/admin/incoming-mail'));
const IncomingMailApprovalPage = lazy(() => import('../pages/admin/incoming-mail-approval'));
const OutcomingMailPage = lazy(() => import('../pages/admin/outcoming-mail'));
const OutcomingMailApprovalPage = lazy(() => import('../pages/admin/outcoming-mail-approval'));
const SubmissionItemPage = lazy(() => import('../pages/admin/submission-item'));
const SubmissionItemApprovalPage = lazy(() => import('../pages/admin/submission-item-approval'));
const SubmissionRepairPage = lazy(() => import('../pages/admin/submission-repair'));
const SubmissionRepairApprovalPage = lazy(() => import('../pages/admin/submission-repair-approval'));
const PresenceEmployeePage = lazy(() => import('../pages/admin/presence-employee'));
const LogBookEmployeePage = lazy(() => import('../pages/admin/log-book-employee'));
const PaidLeaveEmployeePage = lazy(() => import('../pages/admin/paid-leave-employee'));

export default function IndexRoutes() {
  const isLoggedIn = localStorage.getItem('token') === null ? false : true;
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<IndexPage />} />
        <Route path="about" element={<AboutPage />} />
      </Route>
      <Route path="/error">
        <Route path="403" element={<Error403Page />} />
      </Route>
      <Route path="/admin/login" element={<LoginPage />} />
      <Route path="/lupa-password" element={<ForgotPasswordPage />} />
      <Route path="/admin" element={isLoggedIn ? <DashboardLayout /> : <LoginPage />}>
        <Route path="dashboard-pegawai" element={<DashboardPage />} />
        <Route path="kelola-pegawai" element={<EmployeePage />} />
        <Route path="kelola-pegawai/:id" element={<EmployeeDetailPage />} />
        <Route path="kelola-pegawai/:id/biodata" element={<EmployeePersonalPage />} />
        <Route path="kelola-pegawai/:id/alamat" element={<EmployeeAddressPage />} />
        <Route path="kelola-pegawai/:id/keluarga" element={<EmployeeFamilyPage />} />
        <Route path="kelola-pegawai/:id/pendidikan" element={<EmployeeEducationPage />} />
        <Route path="kelola-pegawai/:id/riwayat-jabatan" element={<EmployeeHistoryPositionPage />} />
        <Route path="kelola-pegawai/:id/riwayat-pangkat" element={<EmployeeHistoryRankPage />} />
        <Route path="kelola-pegawai/:id/lain-lain" element={<EmployeeOtherDataPage />} />
        <Route path="kelola-role" element={<RolePage />} />
        <Route path="kelola-master-data" element={<MasterDataPage />} />
        <Route path="kelola-master-data/:type" element={<DynamicMasterDataPage />} />
        <Route path="kelola-presensi-pegawai" element={<PresencePage />} />
        <Route path="kelola-ajuan-cuti" element={<PaidLeavePage />} />
        <Route path="peminjaman-fasilitas" element={<FacilityLoanPage />} />
        <Route path="approval-peminjaman-fasilitas" element={<FacilityLoanApprovalPage />} />
        <Route path="kelola-surat-masuk" element={<IncomingMailPage />} />
        <Route path="approval-kelola-surat-masuk" element={<IncomingMailApprovalPage />} />
        <Route path="kelola-surat-keluar" element={<OutcomingMailApprovalPage />} />
        <Route path="ajuan-surat-keluar" element={<OutcomingMailPage />} />
        <Route path="ajuan-alat-barang" element={<SubmissionItemPage />} />
        <Route path="approval-ajuan-alat-barang" element={<SubmissionItemApprovalPage />} />
        <Route path="ajuan-perbaikan" element={<SubmissionRepairPage />} />
        <Route path="approval-ajuan-perbaikan" element={<SubmissionRepairApprovalPage />} />
        <Route path="presensi" element={<PresenceEmployeePage />} />
        <Route path="presensi/log-book" element={<LogBookEmployeePage />} />
        <Route path="pengajuan-cuti" element={<PaidLeaveEmployeePage />} />
        <Route path="kelola-siswa" element={<StudentPage />} />
        <Route path="pengadaan-bahan-jasa" element={<ProcurementPage />} />
        <Route path="approval-pengadaan-bahan-jasa" element={<ProcurementApprovalPage />} />
        <Route path="notifikasi" element={<NotificationPage />} />
        <Route path="profile" element={<ProfilePage />} />
      </Route>
      {/* <Route path="/test" element={<MainLayout />}>
            </Route> */}
    </Routes>
  );
}
