import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {  client, BASE_URL } from '../api/client'
import { getThunkName, createOrUpdateThunkName } from './base'

export const thunkRolePermission = 'api/roles-permissions'
export const sliceRolePermissionName = 'roles-permissions'

export const getRolePermission = createAsyncThunk(getThunkName(thunkRolePermission), async (idRole, { rejectWithValue }) => {
    try {
        const response = await client.get(BASE_URL + thunkRolePermission + "/" + idRole, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        })
        return response.data
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const createOrUpdateRolePermission = createAsyncThunk(createOrUpdateThunkName(thunkRolePermission), async (body, { rejectWithValue }) => {
    try {
        const response = await client.post(BASE_URL + thunkRolePermission + "/create-or-update", body,{
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        })
        return response.data
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const rolePermissionSlice = createSlice({
    name: sliceRolePermissionName,
    initialState: {
        get: {
            data: [],
            status: 'idle',
            message: '',
            error: '',
        },
        createOrUpdate: {
            data: {},
            status: 'idle',
            message: '',
            error: '',
        },
    },
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(getRolePermission.pending, (state, action) => {
                state.get.data = []
                state.get.status = 'loading'
                state.get.message = ''
                state.get.error = ''
            })
            .addCase(getRolePermission.fulfilled, (state, action) => {
                state.get.data = action.payload.data
                state.get.status = 'succeeded'
                state.get.message = action.payload.message
                state.get.error = ''
            })
            .addCase(getRolePermission.rejected, (state, action) => {
                state.get.data = []
                state.get.status = 'failed'
                state.get.message = action.payload.message
                state.get.error = action.payload.error
            })
            .addCase(createOrUpdateRolePermission.pending, (state, action) => {
                state.createOrUpdate.data = {}
                state.createOrUpdate.status = 'loading'
                state.createOrUpdate.message = ''
                state.createOrUpdate.error = ''
            })
            .addCase(createOrUpdateRolePermission.fulfilled, (state, action) => {
                state.createOrUpdate.data = action.payload.data
                state.createOrUpdate.status = 'succeeded'
                state.createOrUpdate.message = action.payload.message
                state.createOrUpdate.error = ''
            })
            .addCase(createOrUpdateRolePermission.rejected, (state, action) => {
                state.createOrUpdate.data = {}
                state.createOrUpdate.status = 'failed'
                state.createOrUpdate.message = action.payload.message
                state.createOrUpdate.error = action.payload.error
            })
    }
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = loginSlice.actions

export default rolePermissionSlice.reducer