import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName } from './base';

export const thunkProcurementApi = 'api/pengadaan-bahan-jasa';
export const sliceProcurementName = 'pengadaan-bahan-jasa';

export const getProcurement = createAsyncThunk(getThunkName(thunkProcurementApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkProcurementApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createProcurement = createAsyncThunk(createThunkName(thunkProcurementApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkProcurementApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateProcurement = createAsyncThunk(updateThunkName(thunkProcurementApi), async (body, { rejectWithValue }) => {
  try {
    const { id, ...params } = body;
    const response = await client.put(BASE_URL + `${thunkProcurementApi}/${id}`, params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const downloadProcurement = createAsyncThunk(getThunkName(thunkProcurementApi + '/pdf'), async (id, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + `${thunkProcurementApi}/${id}/pdf`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const ProcurementSlice = createSlice({
  name: sliceProcurementName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    download: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: (state) => {
      state.download = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProcurement.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getProcurement.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getProcurement.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(createProcurement.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createProcurement.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createProcurement.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateProcurement.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateProcurement.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateProcurement.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(downloadProcurement.pending, state => {
        state.download.data = {};
        state.download.status = 'loading';
        state.download.message = '';
        state.download.error = '';
      })
      .addCase(downloadProcurement.fulfilled, (state, action) => {
        state.download.data = action.payload.data;
        state.download.status = 'succeeded';
        state.download.message = action.payload.message;
        state.download.error = '';
      })
      .addCase(downloadProcurement.rejected, (state, action) => {
        state.download.data = {};
        state.download.status = 'failed';
        state.download.message = action.payload.message;
        state.download.error = action.payload.error;
      });
  },
});

export const { reset } = ProcurementSlice.actions;
export default ProcurementSlice.reducer;
