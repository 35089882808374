import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clientFormData, client, BASE_URL } from '../api/client';
import { getThunkName, showThunkName, createThunkName, updateThunkName, deleteThunkName } from './base';

export const thunkEmployeeApi = 'api/pegawai';
export const thunkImportEmployeeApi = 'api/pegawai/import-xls';
export const thunkExportEmployeeApi = 'api/pegawai/export-xls';
export const sliceEmployeeName = 'pegawai';

export const deleteEmployee = createAsyncThunk(deleteThunkName(thunkEmployeeApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkEmployeeApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const importEmployee = createAsyncThunk(createThunkName(thunkImportEmployeeApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkImportEmployeeApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const exportEmployee = createAsyncThunk(createThunkName(thunkExportEmployeeApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkExportEmployeeApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateEmployee = createAsyncThunk(updateThunkName(thunkEmployeeApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkEmployeeApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createEmployee = createAsyncThunk(createThunkName(thunkEmployeeApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkEmployeeApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getEmployee = createAsyncThunk(getThunkName(thunkEmployeeApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkEmployeeApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const showEmployee = createAsyncThunk(showThunkName(thunkEmployeeApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkEmployeeApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const employeeSlice = createSlice({
  name: sliceEmployeeName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    show: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    import: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    export: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: (state) => {
      state.export = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEmployee.pending, (state, action) => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(showEmployee.pending, (state, action) => {
        state.show.data = {};
        state.show.status = 'loading';
        state.show.message = '';
        state.show.error = '';
      })
      .addCase(showEmployee.fulfilled, (state, action) => {
        state.show.data = action.payload.data;
        state.show.status = 'succeeded';
        state.show.message = action.payload.message;
        state.show.error = '';
      })
      .addCase(showEmployee.rejected, (state, action) => {
        state.show.data = {};
        state.show.status = 'failed';
        state.show.message = action.payload.message;
        state.show.error = action.payload.error;
      })
      .addCase(importEmployee.pending, (state, action) => {
        state.import.data = {};
        state.import.status = 'loading';
        state.import.message = '';
        state.import.error = '';
      })
      .addCase(importEmployee.fulfilled, (state, action) => {
        state.import.data = action.payload.data;
        state.import.status = 'succeeded';
        state.import.message = action.payload.message;
        state.import.error = '';
      })
      .addCase(importEmployee.rejected, (state, action) => {
        state.import.data = {};
        state.import.status = 'failed';
        state.import.message = action.payload.message;
        state.import.error = action.payload.error;
      })
      .addCase(exportEmployee.pending, (state, action) => {
        state.export.data = {};
        state.export.status = 'loading';
        state.export.message = '';
        state.export.error = '';
      })
      .addCase(exportEmployee.fulfilled, (state, action) => {
        state.export.data = action.payload.data;
        state.export.status = 'succeeded';
        state.export.message = action.payload.message;
        state.export.error = '';
      })
      .addCase(exportEmployee.rejected, (state, action) => {
        state.export.data = {};
        state.export.status = 'failed';
        state.export.message = action.payload.message;
        state.export.error = action.payload.error;
      })
      .addCase(createEmployee.pending, (state, action) => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateEmployee.pending, (state, action) => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(deleteEmployee.pending, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteEmployee.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export const { reset } = employeeSlice.actions;
export default employeeSlice.reducer;
