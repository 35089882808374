import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client, BASE_URL } from '../api/client'

export const thunkMyMenu = 'api/auth/my-menu'

export const myMenu = createAsyncThunk(thunkMyMenu, async (_, { rejectWithValue }) => {
    try {
        const response = await client.get(BASE_URL + thunkMyMenu, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        })
        
        localStorage.setItem('my-menu', JSON.stringify(response.data.data))
        return response.data
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const sliceMyMenu = 'my-menu'

export const myMenuSlice = createSlice({
    name: sliceMyMenu,
    initialState: {
        data: [],
        status: 'idle',
        message: '',
        error: '',
    },
    reducers: {
        setMyMenu: (state, action) => {
            state.myMenu = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(myMenu.pending, (state, action) => {
                state.data = {}
                state.status = 'loading'
                state.message = ''
                state.error = ''
            })
            .addCase(myMenu.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.status = 'succeeded'
                state.message = action.payload.message
                state.error = ''
            })
            .addCase(myMenu.rejected, (state, action) => {
                state.data = {}
                state.status = 'failed'
                state.message = action.payload.message
                state.error = action.payload.error
            })
    }
})

export const { setMyMenu } = myMenuSlice.actions
export default myMenuSlice.reducer