import * as React from 'react';
import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MoreIcon from '@mui/icons-material/MoreVert';

import { Box, Toolbar, List, Typography, Divider, IconButton, Icon, ListItemButton, ListItemIcon, ListItemText, Badge, Menu, MenuItem } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import { doLogout } from '../../../redux/auth';
import { myMenu } from '../../../redux/my-menu';
import { getNotificationTotalUnread } from '../../../redux/notification';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  centerText: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: '500',
  },
  textColor1: {
    color: '#505256',
  },
}));

const drawerWidth = 240;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function wordWrap(str, maxWidth = 20) {
  let newLineStr = '<br />';
  let found = false;
  let res = '';
  while (str.length > maxWidth) {
    found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join('');
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('');
      str = str.slice(maxWidth);
    }
  }

  return res + str;
}

function testWhite(x) {
  const white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
}

function ItemDrawerAdmin(props) {
  return (
    <ListItemButton
      key={props.text}
      sx={{
        minHeight: 48,
        justifyContent: props.isOpen ? 'initial' : 'center',
        px: 2.5,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: props.isOpen ? '12px' : 'auto',
          justifyContent: 'center',
        }}
      >
        <Icon>{props.icon}</Icon>
      </ListItemIcon>

      <ListItemText sx={{ opacity: props.isOpen ? 1 : 0 }}>
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: wordWrap(props.text) }} />
      </ListItemText>
    </ListItemButton>
  );
}

export default function Header() {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [current_role, setCurrentRole] = useState('');

  const myMenuState = useSelector(state => state.myMenu);
  const notification = useSelector(state => state.notification.unread.data);

  useEffect(() => {
    dispatch(myMenu());
    dispatch(getNotificationTotalUnread());
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  let activeStyleNav = {
    textDecoration: 'none',
    background: '#bfe3ff70',
    color: '#1565c0',
  };

  let inActiveStyleNav = {
    textDecoration: 'none',
    background: '#bfe3ff70',
    color: '#555555',
  };

  let activeBoxStyle = {
    marginLeft: '8px',
    marginTop: '16px',
    marginBottom: '8px',
    marginRight: '8px',
    borderRadius: '12px',
    boxShadow: 3,
    textDecoration: 'none',
    background: '#bfe3ff70',
  };

  let inActiveBoxStyle = {
    marginLeft: '8px',
    marginTop: '16px',
    marginBottom: '8px',
    marginRight: '8px',
    borderRadius: '12px',
    boxShadow: 3,
    textDecoration: 'none',
  };

  const handleLogout = () => {
    dispatch(doLogout());
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const getAllRoles = () => {
    const stringRoles = localStorage.getItem('roles');
    return JSON.parse(stringRoles);
  };

  const handleChangeRoles = role => {
    console.log('handleChangeRoles' + role.token);
    localStorage.setItem('token', 'Bearer ' + role.token);
    localStorage.setItem('current_role', role.nama);
    setCurrentRole(localStorage.getItem('current_role'));
    dispatch(myMenu());
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ padding: '32px 32px 32px 32px' }}
    >
      <MenuItem key={'Ganti Role'}>Ganti Role</MenuItem>
      <Divider />
      {getAllRoles().map(item => {
        if (item.nama === current_role) {
          return (
            <MenuItem selected={true} key={item.id}>
              {item.nama}
            </MenuItem>
          );
        }
        return (
          <MenuItem key={item.id} onClick={() => handleChangeRoles(item)}>
            {item.nama}
          </MenuItem>
        );
      })}
      <Divider />
      <MenuItem key={'Profile'} onClick={() => handleMenuClose()} to={'/admin/profile'} component={Link}>
        Profile
      </MenuItem>
      <MenuItem key={'Logout'} onClick={() => handleLogout()}>
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  // eslint-disable-next-line
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton onClick={() => navigate('/admin/notifikasi')} size="large" aria-label="show new notifications" color="inherit">
          <Badge badgeContent={notification} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          background: 'linear-gradient(to right, #42a5f5, #1565c0)',
        }}
        open={open}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}></Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton onClick={() => navigate('/admin/notifikasi')} size="large" aria-label="show new notifications" color="inherit">
              <Badge badgeContent={notification} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img alt="Logo SMTI" src="/logo_smti_sis.png" style={{ width: '40px' }}></img>
          <Typography variant="h6" component="h2" className={[classes.boldText].join(' ')} sx={{ width: '100%', pl: '15px', fontWeight: 'bold' }}>
            SMTI E-OFFICE
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ display: 'none' }} /> : <ChevronLeftIcon sx={{ display: 'none' }} />}
          </IconButton>
        </DrawerHeader>

        <List
          sx={{
            marginTop: '16px',
          }}
        >
          {Array.from(myMenuState.data).map((item, index) => {
            return (
              <div key={index}>
                <Typography
                  key={item.group_name}
                  sx={{
                    marginTop: '16px',
                    marginLeft: '16px',
                    marginBottom: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  {open ? item.group_name : item.group_name.slice(0, 3)}
                </Typography>
                {item.permissions.map((subitem, index) => {
                  return (
                    <div key={index}>
                      <NavLink
                        to={subitem.href}
                        style={({ isActive }) => (isActive ? activeStyleNav : inActiveStyleNav)}
                        children={({ isActive }) =>
                          isActive ? (
                            <>
                              <Box sx={activeBoxStyle}>
                                <ItemDrawerAdmin text={subitem.name} isOpen={open} icon={subitem.icon} />
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box sx={inActiveBoxStyle}>
                                <ItemDrawerAdmin text={subitem.name} isOpen={open} icon={subitem.icon} />
                              </Box>
                            </>
                          )
                        }
                      ></NavLink>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </List>
      </Drawer>
      {/* {renderMobileMenu} */}
      {renderMenu}
    </>
  );
}
