import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clientFormData, BASE_URL } from '../api/client';
import { createThunkName } from './base';

export const thunkStorageApi = 'api/storage/upload';
export const sliceStorageName = 'storage-upload';

export const createStorage = createAsyncThunk(createThunkName(thunkStorageApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkStorageApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const StorageSlice = createSlice({
  name: sliceStorageName,
  initialState: {
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createStorage.pending, (state, action) => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createStorage.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createStorage.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      });
  },
});

export default StorageSlice.reducer;
