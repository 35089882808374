import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName, showThunkName, deleteThunkName } from './base';

export const thunkLogBookApi = 'api/log-harian-pegawai';
export const sliceLogBookName = 'log-harian-pegawai';

export const getLogBook = createAsyncThunk(getThunkName(thunkLogBookApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkLogBookApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createLogBook = createAsyncThunk(createThunkName(thunkLogBookApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkLogBookApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateLogBook = createAsyncThunk(updateThunkName(thunkLogBookApi), async (body, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkLogBookApi, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const showLogBook = createAsyncThunk(showThunkName(thunkLogBookApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkLogBookApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteLogBook = createAsyncThunk(deleteThunkName(thunkLogBookApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkLogBookApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const LogBookSlice = createSlice({
  name: sliceLogBookName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    show: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLogBook.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getLogBook.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getLogBook.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(createLogBook.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createLogBook.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createLogBook.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateLogBook.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateLogBook.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateLogBook.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(showLogBook.pending, state => {
        state.show.data = {};
        state.show.status = 'loading';
        state.show.message = '';
        state.show.error = '';
      })
      .addCase(showLogBook.fulfilled, (state, action) => {
        state.show.data = action.payload.data;
        state.show.status = 'succeeded';
        state.show.message = action.payload.message;
        state.show.error = '';
      })
      .addCase(showLogBook.rejected, (state, action) => {
        state.show.data = {};
        state.show.status = 'failed';
        state.show.message = action.payload.message;
        state.show.error = action.payload.error;
      })
      .addCase(deleteLogBook.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteLogBook.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteLogBook.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default LogBookSlice.reducer;
