import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createOrUpdateThunkName, deleteThunkName } from './base';
import { thunkEmployeeApi } from './employee';

export const thunkEmployeeFamily = 'keluarga';
export const sliceEmployeeFamilyName = 'keluarga-pegawai';

export const getEmployeeFamily = createAsyncThunk(getThunkName(thunkEmployeeFamily), async (idEmployee, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeFamily, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createOrUpdateEmployeeFamily = createAsyncThunk(createOrUpdateThunkName(thunkEmployeeFamily), async (body, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkEmployeeApi + '/' + body.id_peg_biodata + '/' + thunkEmployeeFamily, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteEmployeeFamily = createAsyncThunk(deleteThunkName(thunkEmployeeFamily), async ({ idEmployee, id }, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeFamily + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const EmployeeFamilySlice = createSlice({
  name: sliceEmployeeFamilyName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
    },
    createOrUpdate: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEmployeeFamily.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
      })
      .addCase(getEmployeeFamily.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
      })
      .addCase(getEmployeeFamily.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
      })
      .addCase(createOrUpdateEmployeeFamily.pending, state => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = 'loading';
        state.createOrUpdate.message = '';
        state.createOrUpdate.error = '';
      })
      .addCase(createOrUpdateEmployeeFamily.fulfilled, (state, action) => {
        state.createOrUpdate.data = action.payload.data;
        state.createOrUpdate.status = 'succeeded';
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = '';
      })
      .addCase(createOrUpdateEmployeeFamily.rejected, (state, action) => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = 'failed';
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = action.payload.error;
      })
      .addCase(deleteEmployeeFamily.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteEmployeeFamily.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteEmployeeFamily.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default EmployeeFamilySlice.reducer;
