import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName, deleteThunkName } from './base';

export const thunkFacilityLoanApi = 'api/peminjaman';
export const sliceFacilityLoanName = 'peminjaman';

export const getFacilityLoan = createAsyncThunk(getThunkName(thunkFacilityLoanApi), async ({ type = 'barang', params = '' }, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkFacilityLoanApi + '-' + type + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createFacilityLoan = createAsyncThunk(createThunkName(thunkFacilityLoanApi), async ({ type = 'barang', body }, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkFacilityLoanApi + '-' + type, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateFacilityLoan = createAsyncThunk(updateThunkName(thunkFacilityLoanApi), async ({ type = 'barang', body }, { rejectWithValue }) => {
  try {
    const response = await client.put(BASE_URL + thunkFacilityLoanApi + '-' + type, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteFacilityLoan = createAsyncThunk(deleteThunkName(thunkFacilityLoanApi), async ({ type = 'barang', id }, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkFacilityLoanApi + '-' + type + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getFacilityLoanCalendar = createAsyncThunk(getThunkName(thunkFacilityLoanApi + '/calendar'), async ({ type = 'barang', params = '' }, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkFacilityLoanApi + '-' + type + '/calendar?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getFacilityCalendar = async ({ type, params }) => {
    const response = await client.get(BASE_URL + thunkFacilityLoanApi + '-' + type + '/calendar?' + params, {
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });
  return response.data;
}

export const FacilityLoanSlice = createSlice({
  name: sliceFacilityLoanName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    calendar: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFacilityLoan.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getFacilityLoan.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getFacilityLoan.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(createFacilityLoan.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createFacilityLoan.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createFacilityLoan.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateFacilityLoan.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateFacilityLoan.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateFacilityLoan.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(deleteFacilityLoan.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteFacilityLoan.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteFacilityLoan.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      })
      .addCase(getFacilityLoanCalendar.pending, state => {
        state.calendar.data = [];
        state.calendar.status = 'loading';
        state.calendar.message = '';
        state.calendar.error = '';
      })
      .addCase(getFacilityLoanCalendar.fulfilled, (state, action) => {
        state.calendar.data = action.payload.data;
        state.calendar.status = 'succeeded';
        state.calendar.message = action.payload.message;
        state.calendar.error = '';
      })
      .addCase(getFacilityLoanCalendar.rejected, (state, action) => {
        state.calendar.data = [];
        state.calendar.status = 'failed';
        state.calendar.message = action.payload.message;
        state.calendar.error = action.payload.error;
      })
  },
});

export default FacilityLoanSlice.reducer;
