import * as React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Outlet } from "react-router-dom"

import {
    Box,
    Toolbar,
    CssBaseline,
} from "@mui/material"
import Header from './header'

const customTheme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

// const customTheme = createTheme()

export default function DashboardLayout() {
    return (
        <ThemeProvider theme={customTheme}>
            <Box sx={{
                display: 'flex',
                fontFamily: 'Segoe UI Emoji',
            }}>
                <CssBaseline />
                <Header />
                <Box component="main"
                    sx={{
                        color: '#555555',
                        backgroundColor: '#edf4fc',
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}>
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    )
}
