import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';

export const thunkSettingsName = 'api/settings';
export const sliceSettingsName = 'settings';

export const getSettings = createAsyncThunk(thunkSettingsName, async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkSettingsName);
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const settingsSlice = createSlice({
  name: sliceSettingsName,
  initialState: {
    data: {},
    status: 'idle',
    message: '',
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSettings.pending, (state, action) => {
        state.data = {};
        state.status = 'loading';
        state.message = '';
        state.error = null;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.status = 'succeeded';
        state.message = action.payload.message;
        state.error = null;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.data = {};
        state.status = 'failed';
        state.message = action.payload.message;
        state.error = action.payload.errors;
      });
  },
});

export default settingsSlice.reducer;
