import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client, BASE_URL } from '../api/client'

export const thunkGetProfileName = 'api/user/profile'
export const sliceProfileName = 'profile'

export const getProfile = createAsyncThunk(thunkGetProfileName, async (_, { rejectWithValue }) => {
    try {
        const response = await client.get(BASE_URL + thunkGetProfileName, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        })
        return response.data
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const profileSlice = createSlice({
    name: sliceProfileName,
    initialState: {
        data: {},
        status: 'idle',
        message: '',
        error: '',
    },
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(getProfile.pending, (state, action) => {
                state.data = {}
                state.status = 'loading'
                state.message = ''
                state.error = ''
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.status = 'succeeded'
                state.message = action.payload.message
                state.error = ''
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.data = {}
                state.status = 'failed'
                state.message = action.payload.message
                state.error = action.payload.error
            })
    }
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = loginSlice.actions

export default profileSlice.reducer
