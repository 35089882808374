import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client, clientFormData, BASE_URL } from "../api/client";

export const thunkLoginName = "api/auth/login";
export const thunkLogoutName = "api/auth/logout";
export const thunkForgotPasswordName = "api/auth/forgot-password";
export const thunkForgotChangePasswordName = "api/auth/forgot-change-password";
export const thunkChangePasswordName = "api/auth/change-password";

export const sliceAuthName = "session";

export const postLogin = createAsyncThunk(
  thunkLoginName,
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientFormData.post(
        BASE_URL + thunkLoginName,
        body
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const doLogout = createAsyncThunk(thunkLogoutName, () => {
  return {
    status: 200,
    data: { ok: "ok" },
    url: "",
  };
});

export const postForgotPassword = createAsyncThunk(
  thunkForgotPasswordName,
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientFormData.post(
        BASE_URL + thunkForgotPasswordName,
        body
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postForgotChangePassword = createAsyncThunk(
  thunkForgotChangePasswordName,
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientFormData.post(
        BASE_URL + thunkForgotChangePasswordName,
        body
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postChangePassword = createAsyncThunk(
  thunkChangePasswordName,
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientFormData.post(
        BASE_URL + thunkChangePasswordName,
        body,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authSlice = createSlice({
  name: sliceAuthName,
  initialState: {
    login: {
      data: {},
      status: "idle",
      message: "",
      error: "",
    },
    forgotPassword: {
      data: {},
      status: "idle",
      message: "",
      error: "",
    },
    forgotChangePassword: {
      data: {},
      status: "idle",
      message: "",
      error: "",
    },
    changePassword: {
      data: {},
      status: "idle",
      message: "",
      error: "",
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postLogin.pending, (state, action) => {
        state.login.data = {};
        state.login.status = "loading";
        state.login.message = "";
        state.login.error = "";
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.login.data = action.payload.data;
        state.login.status = "succeeded";
        state.login.message = action.payload.message;
        state.login.error = "";
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.login.data = {};
        state.login.status = "failed";
        state.login.message = action.payload.message;
        state.login.error = action.payload.error;
      })
      .addCase(postForgotPassword.pending, (state, action) => {
        state.forgotPassword.data = {};
        state.forgotPassword.status = "loading";
        state.forgotPassword.message = "";
        state.forgotPassword.error = "";
      })
      .addCase(postForgotPassword.fulfilled, (state, action) => {
        state.forgotPassword.data = action.payload.data;
        state.forgotPassword.status = "succeeded";
        state.forgotPassword.message = action.payload.message;
        state.forgotPassword.error = "";
      })
      .addCase(postForgotPassword.rejected, (state, action) => {
        state.forgotPassword.data = {};
        state.forgotPassword.status = "failed";
        state.forgotPassword.message = action.payload.message;
        state.forgotPassword.error = action.payload.error;
      })
      .addCase(postForgotChangePassword.pending, (state, action) => {
        state.forgotChangePassword.data = {};
        state.forgotChangePassword.status = "loading";
        state.forgotChangePassword.message = "";
        state.forgotChangePassword.error = "";
      })
      .addCase(postForgotChangePassword.fulfilled, (state, action) => {
        state.forgotChangePassword.data = action.payload.data;
        state.forgotChangePassword.status = "succeeded";
        state.forgotChangePassword.message = action.payload.message;
        state.forgotChangePassword.error = "";
      })
      .addCase(postForgotChangePassword.rejected, (state, action) => {
        state.forgotChangePassword.data = {};
        state.forgotChangePassword.status = "failed";
        state.forgotChangePassword.message = action.payload.message;
        state.forgotChangePassword.error = action.payload.error;
      })
      .addCase(postChangePassword.pending, (state, action) => {
        state.changePassword.data = {};
        state.changePassword.status = "loading";
        state.changePassword.message = "";
        state.changePassword.error = "";
      })
      .addCase(postChangePassword.fulfilled, (state, action) => {
        state.changePassword.data = action.payload.data;
        state.changePassword.status = "succeeded";
        state.changePassword.message = action.payload.message;
        state.changePassword.error = "";
      })
      .addCase(postChangePassword.rejected, (state, action) => {
        state.changePassword.data = {};
        state.changePassword.status = "failed";
        state.changePassword.message = action.payload.message;
        state.changePassword.error = action.payload.error;
      });
  },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = loginSlice.actions

export default authSlice.reducer;
