import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName } from './base';

export const thunkProcurementApprovalApi = 'api/approval-pengadaan-bahan-jasa';
export const sliceProcurementApprovalName = 'approval-pengadaan-bahan-jasa';

export const getProcurementApproval = createAsyncThunk(getThunkName(thunkProcurementApprovalApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkProcurementApprovalApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateProcurementApproval = createAsyncThunk(updateThunkName(thunkProcurementApprovalApi), async (body, { rejectWithValue }) => {
  try {
    const { id, ...params } = body;
    const response = await client.put(BASE_URL + `${thunkProcurementApprovalApi}/${id}`, params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const downloadProcurementApproval = createAsyncThunk(getThunkName(thunkProcurementApprovalApi + '/pdf'), async (id, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + `${thunkProcurementApprovalApi}/${id}/pdf`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const ProcurementApprovalSlice = createSlice({
  name: sliceProcurementApprovalName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    download: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: (state) => {
      state.download = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProcurementApproval.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getProcurementApproval.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getProcurementApproval.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(updateProcurementApproval.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateProcurementApproval.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateProcurementApproval.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(downloadProcurementApproval.pending, state => {
        state.download.data = {};
        state.download.status = 'loading';
        state.download.message = '';
        state.download.error = '';
      })
      .addCase(downloadProcurementApproval.fulfilled, (state, action) => {
        state.download.data = action.payload.data;
        state.download.status = 'succeeded';
        state.download.message = action.payload.message;
        state.download.error = '';
      })
      .addCase(downloadProcurementApproval.rejected, (state, action) => {
        state.download.data = {};
        state.download.status = 'failed';
        state.download.message = action.payload.message;
        state.download.error = action.payload.error;
      });
  },
});

export const { reset } = ProcurementApprovalSlice.actions;
export default ProcurementApprovalSlice.reducer;
