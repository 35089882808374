import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, clientFormData, BASE_URL } from '../api/client';
import { getThunkName, createOrUpdateThunkName, deleteThunkName } from './base';
import { thunkEmployeeApi } from './employee';

export const thunkEmployeeEducationSupport = 'pendidikan/diklat-penunjang';
export const sliceEmployeeEducationSupportName = 'pendidikan-diklat-penunjang-pegawai';

export const getEmployeeEducationSupport = createAsyncThunk(getThunkName(thunkEmployeeEducationSupport), async (idEmployee, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeEducationSupport, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createOrUpdateEmployeeEducationSupport = createAsyncThunk(
  createOrUpdateThunkName(thunkEmployeeEducationSupport),
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientFormData.post(BASE_URL + thunkEmployeeApi + '/' + body.get('id_peg_biodata') + '/' + thunkEmployeeEducationSupport, body, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteEmployeeEducationSupport = createAsyncThunk(
  deleteThunkName(thunkEmployeeEducationSupport),
  async ({ idEmployee, id }, { rejectWithValue }) => {
    try {
      const response = await client.delete(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeEducationSupport + '/' + id, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const EmployeeEducationSupportSlice = createSlice({
  name: sliceEmployeeEducationSupportName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
    },
    createOrUpdate: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEmployeeEducationSupport.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
      })
      .addCase(getEmployeeEducationSupport.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
      })
      .addCase(getEmployeeEducationSupport.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
      })
      .addCase(createOrUpdateEmployeeEducationSupport.pending, state => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = 'loading';
        state.createOrUpdate.message = '';
        state.createOrUpdate.error = '';
      })
      .addCase(createOrUpdateEmployeeEducationSupport.fulfilled, (state, action) => {
        state.createOrUpdate.data = action.payload.data;
        state.createOrUpdate.status = 'succeeded';
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = '';
      })
      .addCase(createOrUpdateEmployeeEducationSupport.rejected, (state, action) => {
        state.createOrUpdate.data = {};
        state.createOrUpdate.status = 'failed';
        state.createOrUpdate.message = action.payload.message;
        state.createOrUpdate.error = action.payload.error;
      })
      .addCase(deleteEmployeeEducationSupport.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteEmployeeEducationSupport.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteEmployeeEducationSupport.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      });
  },
});

export default EmployeeEducationSupportSlice.reducer;
