import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName } from './base';

export const thunkDashboardEmployeeApi = 'api/dashboard-pegawai';
export const sliceDashboardName = 'dashboard';

export const getDashboardEmployee = createAsyncThunk(getThunkName(thunkDashboardEmployeeApi), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkDashboardEmployeeApi, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const DashboardSlice = createSlice({
  name: sliceDashboardName,
  initialState: {
    employee: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDashboardEmployee.pending, state => {
        state.employee.data = {};
        state.employee.status = 'loading';
        state.employee.message = '';
        state.employee.error = '';
      })
      .addCase(getDashboardEmployee.fulfilled, (state, action) => {
        state.employee.data = action.payload.data;
        state.employee.status = 'succeeded';
        state.employee.message = action.payload.message;
        state.employee.error = '';
      })
      .addCase(getDashboardEmployee.rejected, (state, action) => {
        state.employee.data = {};
        state.employee.status = 'failed';
        state.employee.message = action.payload;
        state.employee.error = action.error.message;
      })
  },
});

export default DashboardSlice.reducer;
