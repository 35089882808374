import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, BASE_URL } from '../api/client';
import { getThunkName, createOrUpdateThunkName } from './base';
import { thunkEmployeeApi } from './employee';

export const thunkEmployeeAddress = 'alamat';
export const sliceEmployeeAddressName = 'alamat-pegawai';

export const getEmployeeAddress = createAsyncThunk(getThunkName(thunkEmployeeAddress), async (idEmployee, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkEmployeeApi + '/' + idEmployee + '/' + thunkEmployeeAddress, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createEmployeeAddress = createAsyncThunk(createOrUpdateThunkName(thunkEmployeeAddress), async (body, { rejectWithValue }) => {
  try {
    const response = await client.post(BASE_URL + thunkEmployeeApi + '/' + body.id_peg_biodata + '/' + thunkEmployeeAddress, body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const EmployeeAddressSlice = createSlice({
  name: sliceEmployeeAddressName,
  initialState: {
    get: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEmployeeAddress.pending, state => {
        state.get.data = {};
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
      })
      .addCase(getEmployeeAddress.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
      })
      .addCase(getEmployeeAddress.rejected, (state, action) => {
        state.get.data = {};
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
      })
      .addCase(createEmployeeAddress.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createEmployeeAddress.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createEmployeeAddress.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      });
  },
});

export default EmployeeAddressSlice.reducer;
