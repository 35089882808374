import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client, clientFormData, BASE_URL } from '../api/client';
import { getThunkName, createThunkName, updateThunkName, deleteThunkName } from './base';

export const thunkIncomingMailApi = 'api/kelola-surat-masuk';
export const thunkIncomingMailListEmployeeApi = 'api/kelola-surat-masuk/pegawai-list';
export const sliceIncomingMailName = 'kelola-surat-masuk';
export const thunkExportIncomingMailApi = 'api/kelola-surat-masuk/export-xlsx';

export const exportIncomingMail = createAsyncThunk(createThunkName(thunkExportIncomingMailApi), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkExportIncomingMailApi, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getIncomingMailListEmployee = createAsyncThunk(getThunkName(thunkIncomingMailListEmployeeApi), async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkIncomingMailListEmployeeApi, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getIncomingMail = createAsyncThunk(getThunkName(thunkIncomingMailApi), async (params = '', { rejectWithValue }) => {
  try {
    const response = await client.get(BASE_URL + thunkIncomingMailApi + '?' + params, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createIncomingMail = createAsyncThunk(createThunkName(thunkIncomingMailApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkIncomingMailApi + '/create', body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateIncomingMail = createAsyncThunk(updateThunkName(thunkIncomingMailApi), async (body, { rejectWithValue }) => {
  try {
    const response = await clientFormData.post(BASE_URL + thunkIncomingMailApi + '/update', body, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteIncomingMail = createAsyncThunk(deleteThunkName(thunkIncomingMailApi), async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete(BASE_URL + thunkIncomingMailApi + '/' + id, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const IncomingMailSlice = createSlice({
  name: sliceIncomingMailName,
  initialState: {
    get: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
      pagination: {},
    },
    getEmployee: {
      data: [],
      status: 'idle',
      message: '',
      error: '',
    },
    create: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    update: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    delete: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
    export: {
      data: {},
      status: 'idle',
      message: '',
      error: '',
    },
  },
  reducers: {
    reset: state => {
      state.export = {
        data: {},
        status: 'idle',
        message: '',
        error: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getIncomingMail.pending, state => {
        state.get.data = [];
        state.get.status = 'loading';
        state.get.message = '';
        state.get.error = '';
        state.get.pagination = {};
      })
      .addCase(getIncomingMail.fulfilled, (state, action) => {
        state.get.data = action.payload.data;
        state.get.status = 'succeeded';
        state.get.message = action.payload.message;
        state.get.error = '';
        state.get.pagination = action.payload.meta;
      })
      .addCase(getIncomingMail.rejected, (state, action) => {
        state.get.data = [];
        state.get.status = 'failed';
        state.get.message = action.payload.message;
        state.get.error = action.payload.error;
        state.get.pagination = {};
      })
      .addCase(getIncomingMailListEmployee.pending, state => {
        state.getEmployee.data = [];
        state.getEmployee.status = 'loading';
        state.getEmployee.message = '';
        state.getEmployee.error = '';
      })
      .addCase(getIncomingMailListEmployee.fulfilled, (state, action) => {
        state.getEmployee.data = action.payload.data;
        state.getEmployee.status = 'succeeded';
        state.getEmployee.message = action.payload.message;
        state.getEmployee.error = '';
      })
      .addCase(getIncomingMailListEmployee.rejected, (state, action) => {
        state.getEmployee.data = [];
        state.getEmployee.status = 'failed';
        state.getEmployee.message = action.payload.message;
        state.getEmployee.error = action.payload.error;
      })
      .addCase(createIncomingMail.pending, state => {
        state.create.data = {};
        state.create.status = 'loading';
        state.create.message = '';
        state.create.error = '';
      })
      .addCase(createIncomingMail.fulfilled, (state, action) => {
        state.create.data = action.payload.data;
        state.create.status = 'succeeded';
        state.create.message = action.payload.message;
        state.create.error = '';
      })
      .addCase(createIncomingMail.rejected, (state, action) => {
        state.create.data = {};
        state.create.status = 'failed';
        state.create.message = action.payload.message;
        state.create.error = action.payload.error;
      })
      .addCase(updateIncomingMail.pending, state => {
        state.update.data = {};
        state.update.status = 'loading';
        state.update.message = '';
        state.update.error = '';
      })
      .addCase(updateIncomingMail.fulfilled, (state, action) => {
        state.update.data = action.payload.data;
        state.update.status = 'succeeded';
        state.update.message = action.payload.message;
        state.update.error = '';
      })
      .addCase(updateIncomingMail.rejected, (state, action) => {
        state.update.data = {};
        state.update.status = 'failed';
        state.update.message = action.payload.message;
        state.update.error = action.payload.error;
      })
      .addCase(deleteIncomingMail.pending, state => {
        state.delete.data = {};
        state.delete.status = 'loading';
        state.delete.message = '';
        state.delete.error = '';
      })
      .addCase(deleteIncomingMail.fulfilled, (state, action) => {
        state.delete.data = action.payload.data;
        state.delete.status = 'succeeded';
        state.delete.message = action.payload.message;
        state.delete.error = '';
      })
      .addCase(deleteIncomingMail.rejected, (state, action) => {
        state.delete.data = {};
        state.delete.status = 'failed';
        state.delete.message = action.payload.message;
        state.delete.error = action.payload.error;
      })
      .addCase(exportIncomingMail.pending, state => {
        state.export.data = {};
        state.export.status = 'loading';
        state.export.message = '';
        state.export.error = '';
      })
      .addCase(exportIncomingMail.fulfilled, (state, action) => {
        state.export.data = action.payload.data;
        state.export.status = 'succeeded';
        state.export.message = action.payload.message;
        state.export.error = '';
      })
      .addCase(exportIncomingMail.rejected, (state, action) => {
        state.export.data = {};
        state.export.status = 'failed';
        state.export.message = action.payload.message;
        state.export.error = action.payload.error;
      });
  },
});

export const { reset } = IncomingMailSlice.actions
export default IncomingMailSlice.reducer;
